import React, { useEffect } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, LoadingPage, Seo, Text } from 'components';
import Header from 'components/Header';
import { mobile, useQuery } from 'styles/breakpoints';
import Button from 'pages/start/results/components/StartNowButton';
import OnBoarding from '../../../assets/images/onboarding.png';
import { useRouter } from 'apis/history';
import DiscountIcon from 'assets/icons/discount-black.svg';
import { usePageView, useQuizData } from 'utils/hooks';

interface OnBordingCallProps {}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: cent er;
  max-width: 484px;
  max-height: 577px;
  height: 100%;
  width: 100%;
  margin: 2.25rem auto;
  border-radius: 24px;
  @media ${mobile} {
    margin: 1.5rem auto;

    padding: 0 1rem 1rem 1rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.5rem 1.5rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 32px rgba(28, 28, 40, 0.06);
  border-radius: 0 0 24px 24px;

  @media ${mobile} {
    padding: 0 1rem 1rem;
  }
`;

const StyledTitle = styled(Text)`
  font-size: 1.625rem;
  font-weight: 700;
  color: #1c1c28;
  padding: 1rem 0;
  text-align: left;
  @media ${mobile} {
    text-align: left;
    font-size: 1.25rem;
  }
`;

const StyledParagraph = styled(Text)`
  font-size: 1rem;
  @media ${mobile} {
    font-size: 0.875rem;
  }
`;
const StyledButton = styled(Button)`
  background-color: #2fbe8f;
  color: #fff;
  padding: 0.844rem;
  font-weight: 700;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 100px;
  max-width: 370px;
  width: 100%;
`;

const StrikedPriceContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  padding-bottom: 1.5rem;
  padding-top: 2px;
`;

const StrikedPrice = styled(Text)`
  text-decoration: line-through;
  font-size: 1.25rem;
  padding-right: 6px;
  font-weight: 700;
`;

const CurrentPrice = styled(Text)`
  font-size: 1.25rem;
  font-weight: 700;
  color: #2fbe8f;

  @media ${mobile} {
    font-size: 1.25rem;
  }
`;

const StyledImage = styled(DynamicImage)`
  border-radius: 24px 24px 0 0;
`;

const SkipButton = styled.div`
  cursor: pointer;
`;

const SkipText = styled(Text)`
  font-size: 1.25rem;
  text-decoration: underline;
  margin-top: 2.125rem;

  @media ${mobile} {
    font-size: 1rem;
    margin-top: 1.625rem;
  }
`;

const Headline = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9f6f2;
`;

const HeadlineIcon = styled(DiscountIcon)``;
const HeadlineText = styled(Text)`
  padding: 1rem 0;
  padding-left: 0.75rem;
  font-weight: 700;
  font-size: 1.25rem;

  @media ${mobile} {
    padding: 0.813rem 0;
    padding-left: 0.75rem;
    font-size: 1rem;
  }
`;

const WarningText = styled(Text)`
  font-size: 0.75rem;
`;
const WarningContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  padding-top: 1rem;
`;
const OnBordingCall: FC<OnBordingCallProps> = () => {
  const { goToOnBoardingCalc, goToEmail } = useRouter();
  const { isMobile } = useQuery();

  const data = useQuizData('onboarding');

  usePageView();

  useEffect(() => {
    document.body.style.backgroundColor = '#F7F9F8';
    document.body.style.height = '100vh';
    return () => {
      document.body.style.backgroundColor = '#FFFFFF';
    };
  }, []);

  const handleForward = () => {
    goToOnBoardingCalc();
  };

  const handleSkip = () => {
    goToEmail();
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="center" />
      <Headline>
        <HeadlineIcon />
        <HeadlineText>Special Spring Offer</HeadlineText>
      </Headline>
      <Wrapper>
        <StyledImage alt={''} src={OnBoarding} />
        <Container>
          <StyledTitle>{data?.title}</StyledTitle>
          <StyledParagraph>{data?.subTitle}</StyledParagraph>
          <br />
          <StyledParagraph>{data?.secondSubTitle}</StyledParagraph>
          <WarningContainer>
            <WarningText
              dangerouslySetInnerHTML={{ __html: data?.priceText }}
            />
          </WarningContainer>
          <StrikedPriceContainer>
            <StrikedPrice>$300</StrikedPrice>
            <CurrentPrice>Free</CurrentPrice>
          </StrikedPriceContainer>
          <StyledButton fontSize={isMobile ? 1 : 1.125} onClick={handleForward}>
            Book now
          </StyledButton>
        </Container>
        <SkipButton onClick={handleSkip}>
          <SkipText>Skip onboarding</SkipText>
        </SkipButton>
      </Wrapper>
    </>
  );
};
export default OnBordingCall;
